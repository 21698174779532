import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { text, textWrapper } from './collapse';
import { media } from '../utils/media-queries';

// eslint-disable-next-line react/display-name
const CollapsedNav = React.forwardRef(
  ({ active, initialClosed = true, children, background, className }, ref) => (
    <AnimatePresence>
      {active && (
        <StyledNav
          ref={ref}
          className={`${className} light`}
          style={{ overflowY: 'auto', background }}
          variants={textWrapper}
          initial={initialClosed ? 'close' : 'open'}
          animate={active ? 'open' : 'close'}
          exit={initialClosed ? 'close' : 'open'}
        >
          <motion.ul
            key="text"
            variants={text}
            initial={initialClosed ? 'hidden' : 'show'}
            animate={active ? 'show' : 'hidden'}
            exit={initialClosed ? 'hidden' : 'show'}
          >
            {children}
          </motion.ul>
        </StyledNav>
      )}
    </AnimatePresence>
  )
);

const StyledNav = styled(motion.nav)`
  width: 100vw;
  /* important scroll overflow */
  max-height: 71vh;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 4rem;
  font-size: var(--fontsize-2);
  z-index: 2;

  .active {
    height: 8px;
    width: 8px;
    background: var(--highlight-color);
    border-radius: 50%;
    display: inline-block;
    margin-left: var(--spacing-XXXS);
    margin-bottom: 0.2rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-XXS) var(--spacing-XXS) var(--spacing-XXS);
    li {
      padding: var(--spacing-XXXS) 0;
      ul {
        overflow: hidden;
        padding: 0 var(--spacing-XXS);
      }
    }
  }

  @media ${media.M} {
    height: 100vh;
    position: static;
    margin-top: 19vh;
    width: auto;
    min-width: calc(var(--v-spacing-L) * 2);

    ul {
      li {
        padding: calc(var(--spacing-XXXS) / 2) 0;
      }
    }
  }
`;

CollapsedNav.propTypes = {
  active: PropTypes.bool,
  initialClosed: PropTypes.bool,
  children: PropTypes.node,
  background: PropTypes.string,
  className: PropTypes.string,
};

export default CollapsedNav;
