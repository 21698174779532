import React, { useEffect, useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useClientSide } from '../hooks/useClientSide';
import Collapse from './collapse';
import CollapsedNav from './collapsedNav';
import { media } from '../utils/media-queries';

// eslint-disable-next-line react/display-name
const Nav = React.forwardRef(
  ({ active, initialClosed, background, className }, ref) => {
    const data = useStaticQuery(graphql`
      query Nav {
        photosPage: allSanityPhotosPage {
          nodes {
            title
            photoProjects {
              title
              slug {
                current
              }
              hidden
              chapter {
                slug {
                  current
                }
                title
                hidden
              }
            }
          }
        }
        fotosPage: allSanityFotosPage {
          nodes {
            title
            hidden
            fotoProjects {
              title
              slug {
                current
              }
              hidden
              chapter {
                slug {
                  current
                }
                title
                hidden
              }
            }
          }
        }
        videosPage: allSanityVideosPage {
          nodes {
            title
            hidden
          }
        }
        artPage: allSanityArtPage {
          nodes {
            title
            hidden
            artProjects {
              title
              slug {
                current
              }
              hidden
            }
          }
        }
        legalPage: allSanityLegalNotice {
          nodes {
            title
          }
        }
      }
    `);

    const { title, photoProjects } = data.photosPage.nodes[0];
    const { artProjects } = data.artPage.nodes[0];
    const fotosPage = data.fotosPage.nodes[0];

    const isClient = useClientSide();
    const path = isClient && window.location.pathname;

    const [showPhotos, setShowPhotos] = useState(false);
    const [showFotos, setShowFotos] = useState(false);
    const [showArt, setShowArt] = useState(false);

    useEffect(() => {
      if (path.includes('photo')) setShowPhotos(true);
      if (path.includes('foto')) setShowFotos(true);
      if (path.includes('kunst')) setShowArt(true);
    }, [path, photoProjects]);

    return (
      <>
        <CollapsedNav
          ref={ref}
          active={active}
          initialClosed={initialClosed}
          background={background}
          className={className}
        >
          <li>
            <button type="button" onClick={() => setShowPhotos(!showPhotos)}>
              {title}
            </button>
            <Collapse active={showPhotos} initialClosed={showPhotos}>
              {photoProjects.map(
                (item, i) =>
                  !item.hidden && (
                    <li key={i}>
                      <Link to={`/photo/${item.slug.current}`}>
                        {item.title}
                        {isClient && `/photo/${item.slug.current}` === path && (
                          <span className="active"></span>
                        )}
                      </Link>
                      {item.chapter.length !== 0 ||
                        (!item.chapter.hidden && (
                          <Collapse
                            active
                            initialClosed={false}
                            className="chapter"
                          >
                            {item.chapter.map((chapterItem, chapterI) => (
                              <li key={chapterI}>
                                <Link
                                  to={`/photo/${item.slug.current}/${chapterItem.slug.current}`}
                                >
                                  {chapterItem.title}
                                  {isClient &&
                                    `/photo/${item.slug.current}/${chapterItem.slug.current}` ===
                                      path && <span className="active"></span>}
                                </Link>
                              </li>
                            ))}
                          </Collapse>
                        ))}
                    </li>
                  )
              )}
            </Collapse>
          </li>
          {!fotosPage.hidden && (
            <li>
              <button type="button" onClick={() => setShowFotos(!showFotos)}>
                {fotosPage.title}
              </button>
              <Collapse active={showFotos} initialClosed={showFotos}>
                {fotosPage.fotoProjects.map(
                  (item, i) =>
                    !item.hidden && (
                      <li key={i}>
                        <Link to={`/foto/${item.slug.current}`}>
                          {item.title}
                          {isClient &&
                            `/foto/${item.slug.current}` === path && (
                              <span className="active"></span>
                            )}
                        </Link>
                        {item.chapter.length !== 0 && (
                          <Collapse
                            active
                            initialClosed={false}
                            className="chapter"
                          >
                            {item.chapter.map((chapterItem, chapterI) => (
                              <li key={chapterI}>
                                <Link
                                  to={`/foto/${item.slug.current}/${chapterItem.slug.current}`}
                                >
                                  {chapterItem.title}
                                  {isClient &&
                                    `/foto/${item.slug.current}/${chapterItem.slug.current}` ===
                                      path && <span className="active"></span>}
                                </Link>
                              </li>
                            ))}
                          </Collapse>
                        )}
                      </li>
                    )
                )}
              </Collapse>
            </li>
          )}
          {!data.videosPage.nodes[0].hidden && (
            <li>
              <Link to="/video">
                <button type="button"> {data.videosPage.nodes[0].title}</button>
                {isClient && `/video` === path && (
                  <span className="active"></span>
                )}
              </Link>
            </li>
          )}
          {!data.artPage.nodes[0].hidden && (
            <li>
              <button type="button" onClick={() => setShowArt(!showArt)}>
                {data.artPage.nodes[0].title}
              </button>
              <Collapse active={showArt} initialClosed={showArt}>
                {artProjects.map(
                  (item, i) =>
                    !item.hidden && (
                      <li key={i}>
                        <Link to={`/kunst/${item.slug.current}`}>
                          {item.title}
                          {isClient &&
                            `/kunst/${item.slug.current}` === path && (
                              <span className="active"></span>
                            )}
                        </Link>
                      </li>
                    )
                )}
              </Collapse>
            </li>
          )}
          <li>
            <Link to="/preise">
              <button type="button">€</button>
              {isClient && `/preise` === path && (
                <span className="active"></span>
              )}
            </Link>
          </li>
          <StyledMobileImpressum>
            <Link to="/impressum">
              <button type="button">{data.legalPage.nodes[0].title}</button>
            </Link>
          </StyledMobileImpressum>
        </CollapsedNav>
        <StyledImpressum>
          <Link to="/impressum">
            <button type="button">{data.legalPage.nodes[0].title}</button>
            {isClient && `/impressum` === path && (
              <span className="active"></span>
            )}
          </Link>
        </StyledImpressum>
      </>
    );
  }
);

const StyledImpressum = styled.div`
  position: absolute;
  bottom: 10vh;
  left: var(--spacing-XXS);
  z-index: 2;
  font-size: var(--fontsize-0);
  color: var(--highlight-color);
  display: none;

  @media ${media.M} {
    display: block;
  }
`;

const StyledMobileImpressum = styled.div`
  font-size: var(--fontsize-0);
  color: var(--highlight-color);

  @media ${media.M} {
    display: none;
  }
`;

Nav.propTypes = {
  active: PropTypes.bool,
  initialClosed: PropTypes.bool,
  background: PropTypes.string,
  className: PropTypes.string,
};

export default Nav;
