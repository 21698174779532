import React, { useState, useEffect, useRef } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { injectGlobal } from 'emotion';
import styled from 'styled-components';
import { useWindowSize } from '../hooks/useWindowSize';
import { media } from '../utils/media-queries';
import '../styles/app.css';
import Nav from './nav';

const Layout = ({ children, color }) => {
  const data = useStaticQuery(graphql`
    query ColorsTitles {
      settings: allSanitySettings {
        edges {
          node {
            textColor {
              hex
            }
            bgColor {
              hex
            }
            highlightColor {
              hex
            }
          }
        }
      }
      photosPage: allSanityPhotosPage {
        edges {
          node {
            title
          }
        }
      }
      legalNotice: allSanityLegalNotice {
        edges {
          node {
            title
          }
        }
      }
    }
  `);

  const colors = data.settings.edges.map(edge => edge.node);
  const textColor = colors[0].textColor?.hex;
  const bgColor = colors[0].bgColor?.hex;
  const highlightColor = colors[0].highlightColor?.hex;

  injectGlobal`
  :root {
    --text-color: ${textColor};
    --bg-color: ${bgColor};
    --highlight-color: ${highlightColor};
  }
  `;

  const size = useWindowSize();
  const isMobile = size.width < 768;
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [headerHeight, setHeaderHeight] = useState();

  const handleClick = () => setIsNavOpen(!isNavOpen);

  const ref = useRef();

  const headerRef = useRef();
  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight);
  }, [size]);

  useEffect(() => {
    if (isMobile && isNavOpen) {
      disableBodyScroll(ref.current);
    } else if (!(isMobile && isNavOpen)) {
      clearAllBodyScrollLocks();
    }
  }, [isMobile, isNavOpen]);

  return (
    <StyledContentSection style={{ background: color }}>
      <Nav
        active={isNavOpen}
        initialClosed
        background={color}
        ref={ref}
        className="nav-mobile"
      />
      <Nav
        active
        initialClosed={false}
        background={color}
        ref={ref}
        className="nav-desktop"
      />
      <div className="wrapper">
        <StyledHeader
          ref={headerRef}
          className="light"
          onClick={isMobile ? handleClick : undefined}
          style={{ background: color }}
        >
          <h1>mani froh</h1>
        </StyledHeader>
        <main style={{ paddingTop: isMobile && headerHeight }}>{children}</main>
      </div>
    </StyledContentSection>
  );
};

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  z-index: 1;
  padding: var(--spacing-XXS);
  color: var(--highlight-color);
  font-size: var(--fontsize-1);
  display: flex;
  align-items: center;

  @media ${media.M} {
    position: static;
    height: 20vh;
    padding: 0;

    h1 {
      transform: translate(-100px);
    }
  }
`;

const StyledContentSection = styled.section`
  display: flex;
  overflow: hidden;

  .nav-mobile {
    display: block;
  }

  .nav-desktop {
    display: none;
  }

  .wrapper {
    width: 100%;
  }

  @media ${media.M} {
    overflow-x: hidden;

    .nav-desktop {
      display: block;
    }

    .nav-mobile {
      display: none;
    }
  }
`;
Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
