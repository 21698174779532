import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';

export const textWrapper = {
  open: {
    height: 'auto',
    transition: { ease: 'easeOut', duration: 0.4 },
  },
  close: {
    height: 0,
    transition: { ease: 'easeOut', duration: 0.4 },
  },
};

export const text = {
  hidden: { opacity: 0, transition: { duration: 0.15 } },
  show: { opacity: 1, transition: { duration: 0.2, delay: 0.2 } },
};

const Collapse = ({ active, children, className }) => (
  <AnimatePresence>
    {active && (
      <motion.div
        style={{ overflow: 'hidden' }}
        className={className}
        variants={textWrapper}
        initial={active ? 'open' : 'close'}
        animate="open"
        exit="close"
      >
        <motion.ul
          key="text"
          variants={text}
          initial={active ? 'show' : 'hidden'}
          animate="show"
          exit="hidden"
        >
          {children}
        </motion.ul>
      </motion.div>
    )}
  </AnimatePresence>
);

Collapse.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Collapse;
